/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable max-classes-per-file */

'use client';

import { useEffect, useRef, useState } from 'react';

interface FireworksProps {
  backgroundColor?: string;
}

export default function DesktopFireWorks({ backgroundColor = '#000' }: FireworksProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const animationRef = useRef<number>(0);
  const fireworksRef = useRef<any[]>([]);
  const particlesRef = useRef<any[]>([]);
  const lastLaunchRef = useRef<number>(0);
  const isSafariRef = useRef(false);
  const isMobileRef = useRef(false);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    const userAgent = navigator.userAgent.toLowerCase();
    const isSafariBrowser =
      /safari/.test(userAgent) &&
      !/chrome/.test(userAgent) &&
      !/firefox/.test(userAgent) &&
      !/edge/.test(userAgent);
    const isIOS =
      /iphone|ipad|ipod/.test(userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    isSafariRef.current = isSafariBrowser || isIOS;

    isMobileRef.current = /iphone|ipad|ipod|android|webos|blackberry|iemobile|opera mini/i.test(
      userAgent
    );

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Main animation loop
  useEffect(() => {
    if (!canvasRef.current || dimensions.width === 0 || dimensions.height === 0) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Set canvas dimensions
    canvas.width = dimensions.width;
    canvas.height = dimensions.height;

    // Firework class
    class Firework {
      x: number;

      y: number;

      targetY: number;

      speed: number;

      color: string;

      particleCount: number;

      trail: Array<{ x: number; y: number; alpha: number }>;

      angle: number;

      constructor(x: number, targetY: number) {
        this.x = x;
        this.y = dimensions.height;
        this.targetY = targetY;
        this.speed = 1.5 + Math.random() * 2.5;
        this.color = getRandomColor();
        // Limit particle count to avoid performance issues
        this.particleCount = isMobileRef.current
          ? Math.floor(Math.random() * 20) + 20
          : isSafariRef.current
            ? Math.floor(Math.random() * 30) + 30
            : Math.floor(Math.random() * 60) + 60;
        this.trail = [];
        this.angle = Math.random() * 0.4 - 0.2;
      }

      update() {
        this.trail.push({
          x: this.x,
          y: this.y,
          alpha: 1,
        });

        // Only keep recent trail points
        if (this.trail.length > 10) {
          this.trail.shift();
        }

        // Move towards target with angle
        this.y -= this.speed;
        this.x += Math.sin(this.angle) * this.speed;

        // Return true if reached target
        return this.y <= this.targetY;
      }

      draw(ctx: CanvasRenderingContext2D) {
        // Draw trail
        this.trail.forEach((point, index) => {
          const alpha = point.alpha * (index / this.trail.length);
          ctx.beginPath();
          ctx.arc(point.x, point.y, 2, 0, Math.PI * 2);
          ctx.fillStyle = `rgba(255, 255, 255, ${alpha})`;
          ctx.fill();
        });

        // Draw firework
        ctx.beginPath();
        ctx.arc(this.x, this.y, 3, 0, Math.PI * 2);
        ctx.fillStyle = this.color;
        ctx.fill();
      }

      explode() {
        // Create particles - FIXED increment
        for (let i = 0; i < this.particleCount; i++) {
          particlesRef.current.push(new Particle(this.x, this.y, this.color));
        }
      }
    }

    // Particle class
    class Particle {
      x: number;

      y: number;

      color: string;

      velocity: { x: number; y: number };

      alpha: number;

      decay: number;

      gravity: number;

      trail: Array<{ x: number; y: number; alpha: number }>;

      constructor(x: number, y: number, color: string) {
        this.x = x;
        this.y = y;
        this.color = color;

        // Random velocity
        const angle = Math.random() * Math.PI * 2;
        const speed = Math.random() * 3.5 + 0.8;
        this.velocity = {
          x: Math.cos(angle) * speed,
          y: Math.sin(angle) * speed,
        };

        this.alpha = 1;
        this.decay = 0.01 + Math.random() * 0.015;
        this.gravity = 0.06;
        this.trail = [];
      }

      update() {
        // Add current position to trail
        this.trail.push({
          x: this.x,
          y: this.y,
          alpha: this.alpha,
        });

        // Keep only recent trail points
        if (this.trail.length > 5) {
          this.trail.shift();
        }

        // Apply gravity
        this.velocity.y += this.gravity;

        // Move particle
        this.x += this.velocity.x;
        this.y += this.velocity.y;

        // Fade out
        this.alpha -= this.decay;

        // Return true if particle is still visible
        return this.alpha > 0;
      }

      draw(ctx: CanvasRenderingContext2D) {
        // Draw trail
        this.trail.forEach((point, index) => {
          const trailAlpha = point.alpha * (index / this.trail.length) * 0.6;
          ctx.beginPath();
          ctx.arc(point.x, point.y, 1.5, 0, Math.PI * 2);
          ctx.fillStyle = `rgba(${hexToRgb(this.color)}, ${trailAlpha})`;
          ctx.fill();
        });

        // Draw particle
        ctx.beginPath();
        ctx.arc(this.x, this.y, 2, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(${hexToRgb(this.color)}, ${this.alpha})`;
        ctx.fill();
      }
    }

    function hexToRgb(hex: string): string {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? `${Number.parseInt(result[1], 16)}, ${Number.parseInt(result[2], 16)}, ${Number.parseInt(result[3], 16)}`
        : '255, 255, 255';
    }

    function getRandomColor(): string {
      const colors = ['#ff595e', '#ffca3a', '#8ac926', '#1982c4', '#6a4c93', '#ffffff'];
      return colors[Math.floor(Math.random() * colors.length)];
    }

    // Maximum number of fireworks to display at once
    const MAX_FIREWORKS = 10;
    // Maximum number of particles to allow
    const MAX_PARTICLES = 1000;

    const createFirework = (targetX?: number, targetY?: number) => {
      // Limit maximum fireworks for performance
      if (fireworksRef.current.length >= MAX_FIREWORKS) {
        return;
      }

      const x =
        targetX !== undefined
          ? targetX + (Math.random() * 40 - 20)
          : Math.random() * dimensions.width;

      const y =
        targetY !== undefined
          ? targetY + (Math.random() * 30 - 15)
          : Math.random() * (dimensions.height / 2);

      fireworksRef.current.push(new Firework(x, y));
    };

    function animate() {
      if (!ctx) return;

      // Clear canvas with semi-transparent background for trail effect
      ctx.fillStyle = `rgba(0, 0, 0, 0.2)`;
      ctx.fillRect(0, 0, dimensions.width, dimensions.height);

      // Update and draw fireworks
      fireworksRef.current = fireworksRef.current.filter((firework) => {
        firework.draw(ctx);
        const exploded = firework.update();
        if (exploded) {
          // Only explode if we haven't exceeded max particles
          if (particlesRef.current.length < MAX_PARTICLES) {
            firework.explode();
          }
          return false;
        }
        return true;
      });

      // Update and draw particles
      particlesRef.current = particlesRef.current.filter((particle) => {
        particle.draw(ctx);
        return particle.update();
      });

      // Randomly create new fireworks
      const now = Date.now();
      const launchDelay = isMobileRef.current ? 800 : isSafariRef.current ? 600 : 400;

      if (now - lastLaunchRef.current > launchDelay) {
        // Create 1-2 fireworks at random positions
        const count = isMobileRef.current ? 1 : Math.floor(Math.random() * 2) + 1;
        for (let i = 0; i < count; i++) {
          // FIXED increment
          setTimeout(() => createFirework(), i * 100);
        }
        lastLaunchRef.current = now;
      }

      // Continue animation
      animationRef.current = requestAnimationFrame(animate);
    }

    // Start animation
    animate();

    // Create initial fireworks
    const initialCount = isMobileRef.current ? 3 : 5;
    for (let i = 0; i < initialCount; i++) {
      // FIXED increment
      setTimeout(() => createFirework(), i * 200);
    }

    // Cleanup
    return () => {
      cancelAnimationFrame(animationRef.current);
      fireworksRef.current = [];
      particlesRef.current = [];
    };
  }, [dimensions]);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        overflow: 'hidden',
        background: backgroundColor,
      }}
    >
      <canvas
        ref={canvasRef}
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  );
}
