'use client';

import type { Engine } from '@tsparticles/engine';

import { Box } from '@mui/material';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadTextShape } from '@tsparticles/shape-text';
import { loadSlim } from '@tsparticles/slim';
import { useEffect, useState } from 'react';

import { emojiMap } from './options';

interface VibemojiThemeProps {
  variation?: string;
  color?: string;
  count?: number;
}

export function VibemojiTheme({
  variation = 'heart',
  color = '#000',
  count = 30,
}: VibemojiThemeProps) {
  const [init, setInit] = useState(false);

  // Get the emoji character
  const emoji = emojiMap[variation] || '❤️';

  // Initialize tsParticles with the text shape plugin
  useEffect(() => {
    const initializeParticles = async () => {
      await initParticlesEngine(async (engine: Engine) => {
        // Load the slim bundle
        await loadSlim(engine);

        // Load the text shape plugin
        await loadTextShape(engine);
      });

      setInit(true);
    };

    initializeParticles();
  }, []);

  if (!init) return null;

  // Use 'as any' to bypass TypeScript errors while maintaining proper runtime behavior
  const particlesOptions: any = {
    fullScreen: {
      enable: true,
      zIndex: 0,
    },
    background: {
      color: {
        value: color,
      },
    },
    fpsLimit: 60,
    particles: {
      number: {
        value: count,
        density: {
          enable: true,
          area: 800,
        },
      },
      shape: {
        type: 'character',
        options: {
          character: {
            value: emoji,
            font: 'Verdana',
            style: '',
            weight: '400',
          },
        },
      },
      size: {
        value: { min: 20, max: 40 },
      },
      opacity: {
        value: 1,
      },
      move: {
        enable: true,
        speed: 2,
        direction: 'none',
        random: false,
        straight: false,
        outModes: {
          default: 'bounce',
        },
        attract: {
          enable: false,
          rotate: {
            x: 600,
            y: 1200,
          },
        },
      },
      rotate: {
        value: {
          min: 0,
          max: 360,
        },
        direction: 'random',
        animation: {
          enable: true,
          speed: 1,
        },
      },
    },
    detectRetina: true,
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
      }}
    >
      <Particles id="vibemoji-particles" options={particlesOptions} />
    </Box>
  );
}
