'use client';

import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { ThemeVariation } from 'src/types/theme';

// Import your custom Fireworks component
import Fireworks from './cosmics/fireworks/fireworks';

interface CosmicThemeProps {
  variation: ThemeVariation;
  color: string;
}

export const CosmicTheme: React.FC<CosmicThemeProps> = ({ variation, color }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    // Simply set mounted to true on component mount
    setMounted(true);
  }, []);

  // If not mounted yet, return null
  if (!mounted) return null;

  // Return custom Fireworks component if variation is 'fireworks'
  if (variation === 'fireworks') {
    return <Fireworks />;
  }

  // For any other variation, just return a colored background box
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        overflow: 'hidden',
        backgroundColor: color,
        transform: 'translateZ(0)',
      }}
    />
  );
};
