'use client';

import { Box } from '@mui/material';
import { Container } from '@tsparticles/engine';
import { loadFireworksPreset } from '@tsparticles/preset-fireworks';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import React, { useState, useEffect, useRef } from 'react';

interface MobileFireworksProps {
  backgroundColor?: string;
  zIndex?: number;
}

export default function MobileFireworks({
  backgroundColor = '#000',
  zIndex = -1,
}: MobileFireworksProps) {
  const [mounted, setMounted] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  // Initialize tsParticles with fireworks preset
  useEffect(() => {
    const initParticles = async () => {
      await initParticlesEngine(async (engine: any) => {
        // Disable sound globally at the engine level
        if (engine.plugins && engine.plugins.sound) {
          engine.plugins.sound.mute = true;
        }

        // Load the fireworks preset
        await loadFireworksPreset(engine);
      });

      setMounted(true);
    };

    initParticles();
  }, []);

  const particlesLoaded = async (container?: Container): Promise<void> => {
    if (container) {
      // @ts-ignore
      // @ts-nocheck
      if (container.plugins && container.plugins.sound) {
        // @ts-ignore
        // @ts-nocheck
        container.plugins.sound.mute = true;
      }
    }
  };

  // Wait until particles engine is mounted
  if (!mounted) return null;

  // Configure fireworks options for mobile with type fixes
  const fireworksOptions: any = {
    preset: 'fireworks',
    fullScreen: {
      enable: true, // Use our container instead
      zIndex: -1,
    },
    background: {
      color: {
        value: backgroundColor,
      },
    },
    // Disable sounds
    sounds: {
      enable: false,
      events: [],
      volume: 0,
    },
    // Performance optimizations
    fpsLimit: 40, // Lower FPS limit for mobile
    particles: {
      number: {
        value: 0,
        density: {
          enable: true,
          value_area: 800,
        },
      },
      // Enhanced particle settings for better visuals
      opacity: {
        value: 0.9,
      },
      color: {
        value: ['#ff595e', '#ffca3a', '#8ac926', '#1982c4', '#6a4c93', '#ffffff'],
      },
      shape: {
        type: 'circle',
      },
      size: {
        value: { min: 2, max: 4 },
        animation: {
          enable: true,
          speed: 2,
          minimumValue: 0.5,
          sync: false,
        },
      },
      life: {
        duration: {
          value: 3,
          sync: false,
        },
      },
      move: {
        enable: true,
        gravity: {
          enable: true,
          acceleration: 10, // Stronger gravity for more dramatic arcs
          maxSpeed: 20,
        },
        speed: { min: 10, max: 20 }, // Faster particles
        direction: 'none',
        random: true,
        straight: false,
        outModes: 'destroy',
      },
      // Lower quality rendering for better performance
      reduceDuplicates: true,
      // Enable trails for particles
      trail: {
        enable: true,
        length: 10,
        fillColor: {
          value: backgroundColor,
        },
      },
    },
    // Multiple emitters for rockets - more explosions including bottom of screen
    emitters: [
      {
        // Center emitter - main
        direction: 'top',
        life: {
          count: 0,
          duration: 0.1,
          delay: 0,
        },
        rate: {
          delay: 1.2, // Faster rate for more explosions
          quantity: 2, // Launch 2 at once
        },
        size: {
          width: 100,
          height: 0,
        },
        position: {
          y: 100, // From bottom
          x: 50,
        },
        particles: {
          shape: {
            type: 'line',
          },
          size: {
            value: { min: 0.8, max: 1.5 },
          },
          move: {
            straight: true,
            enable: true,
            speed: { min: 15, max: 25 },
            gravity: {
              enable: false,
            },
            trail: {
              enable: true,
              length: 3,
              fillColor: backgroundColor,
            },
          },
          life: {
            duration: {
              value: { min: 0.8, max: 1.2 },
            },
          },
        },
      },
      {
        // Left emitter
        direction: 'top',
        life: {
          count: 0,
          duration: 0.1,
          delay: 0.3,
        },
        rate: {
          delay: 2, // Faster
          quantity: 1,
        },
        size: {
          width: 30, // Wider area
          height: 0,
        },
        position: {
          y: 100,
          x: 20,
        },
        particles: {
          shape: {
            type: 'line',
          },
          size: {
            value: { min: 0.8, max: 1.5 },
          },
          move: {
            straight: true,
            enable: true,
            speed: { min: 15, max: 25 },
            gravity: {
              enable: false,
            },
          },
        },
      },
      {
        // Right emitter
        direction: 'top',
        life: {
          count: 0,
          duration: 0.1,
          delay: 0.5,
        },
        rate: {
          delay: 2.2, // Faster
          quantity: 1,
        },
        size: {
          width: 30, // Wider area
          height: 0,
        },
        position: {
          y: 100,
          x: 80,
        },
        particles: {
          shape: {
            type: 'line',
          },
          size: {
            value: { min: 0.8, max: 1.5 },
          },
          move: {
            straight: true,
            enable: true,
            speed: { min: 15, max: 25 },
            gravity: {
              enable: false,
            },
          },
        },
      },
      {
        // Bottom left - low height explosions
        direction: 'top',
        life: {
          count: 0,
          duration: 0.1,
          delay: 0.7,
        },
        rate: {
          delay: 2.5,
          quantity: 1,
        },
        size: {
          width: 20,
          height: 0,
        },
        position: {
          y: 100,
          x: 30,
        },
        particles: {
          shape: {
            type: 'line',
          },
          size: {
            value: { min: 0.8, max: 1.5 },
          },
          move: {
            straight: true,
            enable: true,
            speed: { min: 10, max: 15 }, // Slower/shorter rise
            gravity: {
              enable: false,
            },
          },
          life: {
            duration: {
              value: { min: 0.5, max: 0.7 }, // Shorter life for low explosions
            },
          },
        },
      },
      {
        // Bottom right - low height explosions
        direction: 'top',
        life: {
          count: 0,
          duration: 0.1,
          delay: 0.9,
        },
        rate: {
          delay: 2.7,
          quantity: 1,
        },
        size: {
          width: 20,
          height: 0,
        },
        position: {
          y: 100,
          x: 70,
        },
        particles: {
          shape: {
            type: 'line',
          },
          size: {
            value: { min: 0.8, max: 1.5 },
          },
          move: {
            straight: true,
            enable: true,
            speed: { min: 10, max: 15 }, // Slower/shorter rise
            gravity: {
              enable: false,
            },
          },
          life: {
            duration: {
              value: { min: 0.5, max: 0.7 }, // Shorter life for low explosions
            },
          },
        },
      },
    ],
    // Enable click interactions
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: 'emitter',
        },
      },
      modes: {
        emitter: {
          // Emit multiple fireworks on click
          direction: 'top',
          life: {
            count: 3, // Multiple bursts
            duration: 0.1,
            delay: 0.1,
          },
          rate: {
            delay: 0.01,
            quantity: 5, // More fireworks on click
          },
          size: {
            width: 20,
            height: 0,
          },
          position: {
            y: 100,
            x: 50,
          },
          // Dynamic custom positioning based on click location
          startCount: 1,
          // Custom particles for click explosions
          particles: {
            move: {
              speed: { min: 15, max: 30 }, // Faster particles for clicks
              gravity: {
                acceleration: 9.8,
              },
            },
            color: {
              value: ['#ff595e', '#ffca3a', '#8ac926', '#1982c4', '#6a4c93', '#ffffff'],
            },
            size: {
              value: { min: 2, max: 5 }, // Larger particles
            },
            opacity: {
              value: 1,
            },
          },
        },
      },
    },
    // Detect retina displays and adjust accordingly
    detectRetina: true,
    // Use hardware acceleration when possible
    motion: {
      disable: false,
      reduce: {
        factor: 2,
        value: true,
      },
    },
    // Plugin configs
    plugins: {
      sound: {
        enable: false,
        mute: true,
        volume: 0,
      },
    },
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
        zIndex,
        overflow: 'hidden',
        background: backgroundColor,
        // Use GPU acceleration when possible
        transform: 'translateZ(0)',
        backfaceVisibility: 'hidden',
        // Use a will-change hint for better performance
        willChange: 'transform',
      }}
    >
      <Particles
        id="mobile-fireworks-particles"
        particlesLoaded={particlesLoaded}
        options={fireworksOptions}
        // @ts-ignore
        // @ts-nocheck
        init={async (engine: any) => {
          // Ensure we disable sound directly on init
          if (engine.plugins && engine.plugins.sound) {
            engine.plugins.sound.mute = true;
          }
        }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
    </Box>
  );
}
