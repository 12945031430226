// File: app/layout.tsx

'use client';

import { Box } from '@mui/material';
import React from 'react';
import { EventThemeConfig } from 'src/types/theme';
import { StreamChat } from 'stream-chat';
import { Chat } from 'stream-chat-react';

import ThemeRenderer from '../event-theme/themed-backgroud';
import Footer from './footer';
import Header from './header';
import TabBar from './TabBar';

type Props = {
  children: React.ReactNode;
  title?: string;
  withLogo?: boolean;
  hideOption?: boolean;
  withProfile?: boolean;
  noFooter?: boolean;
  showTabBar?: boolean;
  theme?: EventThemeConfig;
  eventId?: string; // Optional event ID to load specific theme
};

const client = StreamChat.getInstance('y7fsjd33c926');

export default function MainLayout({
  children,
  title,
  showTabBar,
  withLogo,
  hideOption,
  noFooter,
  withProfile,
  eventId,
  theme,
}: Props) {
  // Set defaults
  withLogo = withLogo ?? true;
  showTabBar = showTabBar ?? true;

  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  const hasTheme = !!theme?.animation;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 1,
      }}
    >
      {hasTheme && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          }}
        >
          <ThemeRenderer themeConfig={theme} />
        </Box>
      )}

      {(withProfile || withLogo || title) && (
        <Header title={title} withLogo={withLogo} withProfile hideOption={hideOption} />
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          zIndex: 2,
          position: 'relative',
        }}
      >
        {children}
      </Box>
      {showTabBar && isMobile && client ? (
        <Chat client={client} theme="str-chat__theme-dark">
          <TabBar />
        </Chat>
      ) : null}

      {!noFooter && <Footer />}
    </Box>
  );
}
