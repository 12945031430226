'use client';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { _socials } from 'src/_mock';
import Iconify from 'src/components/iconify';
import IntercomSupport from 'src/components/intercom/intercom';
import { useResponsive } from 'src/hooks/use-responsive';
import { useTranslate } from 'src/locales';
import { RouterLink } from 'src/routes/components';
import { useRouter } from 'src/routes/hooks';

import { LanguagePopover } from '../_common';

type FooterProps = {
  noTabbar?: boolean;
};

export default function Footer({ noTabbar }: FooterProps) {
  const [isMounted, setIsMounted] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const smUp = useResponsive('up', 'sm');
  const router = useRouter();
  const { t } = useTranslate('commons');

  useEffect(() => {
    setIsMounted(true);
    const { userAgent } = navigator;
    setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent));
  }, []);

  const LEGAL_LINKS = [
    { name: t('links.terms'), href: 'https://trybe.fm/terms' },
    { name: t('links.privacy'), href: 'https://trybe.fm/privacy' },
  ];
  const LINKS = [
    { name: t('buttons.create-event'), href: 'https://nest.trybe.fm/' },
    { name: t('links.contact-us'), href: 'contact' },
  ];

  if (!isMounted) {
    return null;
  }

  return (
    <Box
      component="footer"
      sx={{
        ...(smUp && {
          position: 'fixed',
          zIndex: 1200,
        }),
        ...(isMobile &&
          !noTabbar && {
            pb: 2.2,
          }),
        mt: 3,
        backgroundColor: 'rgba(5, 5, 5, 0.4)', // Transparent white background
        backdropFilter: 'blur(10px)', // Apply blur here
        WebkitBackdropFilter: 'blur(10px)', // Safari support
        width: '100%', // Changed from 100vw to 100% to prevent horizontal scrollbar
        bottom: 0,
        position: 'fixed',
        zIndex: 2,
      }}
    >
      <Divider />

      <Container
        sx={{
          textAlign: { xs: 'center', md: 'unset' },
          py: 1,
        }}
        maxWidth="xl"
      >
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Stack direction={{ xs: 'column', md: 'row' }} alignItems="center" gap={3} width="100%">
              {smUp && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="body2">© 2024 Trybe.fm</Typography>
                </Stack>
              )}

              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                flexWrap="wrap"
                justifyContent={{ xs: 'center', md: 'flex-start' }}
              >
                {!isMobile &&
                  LEGAL_LINKS.map((link) =>
                    link.href === 'contact' ? (
                      <IntercomSupport key={link.name} link />
                    ) : (
                      <Link
                        key={link.name}
                        component={RouterLink}
                        href={link.href}
                        color="inherit"
                        variant="body2"
                        sx={{
                          '&:hover': {
                            color: 'primary.main',
                          },
                        }}
                      >
                        {link.name}
                      </Link>
                    )
                  )}
              </Stack>
            </Stack>
          </Grid>

          <Grid xs={12} md={6}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={isMobile ? 'space-between' : 'flex-end'}
              spacing={1}
            >
              <Stack
                spacing={2}
                direction={isMobile ? 'row-reverse' : 'row'}
                alignItems="center"
                justifyContent={{ xs: 'center', md: 'flex-start' }}
              >
                {LINKS.map((link) =>
                  link.href === 'contact' ? (
                    <IntercomSupport key={link.name} link />
                  ) : (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="inherit"
                      variant="body2"
                      sx={{
                        '&:hover': {
                          color: 'primary.main',
                        },
                      }}
                    >
                      {link.name}
                    </Link>
                  )
                )}
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <LanguagePopover />
                {_socials.map((social) => (
                  <IconButton
                    key={social.name}
                    sx={{
                      '&:hover': {
                        bgcolor: alpha('#ffd369', 0.08),
                      },
                    }}
                    onClick={() => router.replace(social.path)}
                  >
                    <Iconify icon={social.icon} />
                  </IconButton>
                ))}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
