import { IOptions, RecursivePartial } from '@tsparticles/engine';

export interface ThemeOptions {
  options: RecursivePartial<IOptions>;
}

export const lowKeyThemeOptions: Record<string, ThemeOptions> = {
  pattern: {
    options: {
      fullScreen: {
        enable: true,
        zIndex: 0,
      },
      background: {
        color: {
          value: '#000000', // Will be overridden
        },
      },
      particles: {
        number: {
          value: 80,
          density: {
            enable: true,
          },
        },
        color: {
          value: '#ffffff',
        },
        shape: {
          type: 'circle',
        },
        opacity: {
          value: 0.5,
        },
        size: {
          value: 3,
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: '#ffffff',
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 1,
          direction: 'none',
          random: true,
          straight: false,
        },
      },
    },
  },
  confetti: {
    options: {
      fullScreen: {
        enable: true,
        zIndex: 0,
      },
      background: {
        color: {
          value: '#000000', // Will be overridden
        },
      },
      particles: {
        number: {
          value: 80,
          density: {
            enable: true,
          },
        },
        color: {
          value: ['#1E00FF', '#FF0061', '#E1FF00', '#00FF9E'],
        },
        shape: {
          type: ['circle', 'square', 'triangle'],
        },
        opacity: {
          value: 1,
        },
        size: {
          value: 6,
        },
        move: {
          enable: true,
          speed: 3,
          direction: 'bottom',
          random: true,
          straight: false,
          outModes: {
            default: 'out',
          },
        },
      },
    },
  },
};

// Add more theme options as needed (SciFi, BlingBling, Textured, etc.)
// Example of how to structure them:
export const sciFiThemeOptions: Record<string, ThemeOptions> = {
  default: {
    options: {
      fullScreen: {
        enable: true,
        zIndex: 0,
      },
      background: {
        color: {
          value: '#01579B',
        },
      },
      particles: {
        number: {
          value: 50,
          density: {
            enable: true,
          },
        },
        color: {
          value: '#ffffff',
        },
        shape: {
          type: 'circle',
        },
        opacity: {
          value: 0.7,
        },
        size: {
          value: 3,
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: '#ffffff',
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 1,
          direction: 'none',
          random: false,
          straight: false,
        },
      },
    },
  },
  starfield: {
    options: {
      // This is a special case that uses the PIXI.js Starfield component
      // The options aren't used but we need a placeholder
    },
  },
  cyber: {
    options: {
      fullScreen: {
        enable: true,
        zIndex: 0,
      },
      background: {
        color: {
          value: '#000033',
        },
      },
      particles: {
        number: {
          value: 80,
          density: {
            enable: true,
          },
        },
        color: {
          value: ['#00ffff', '#0000ff', '#ff00ff'],
        },
        shape: {
          type: ['circle', 'triangle'],
        },
        opacity: {
          value: 0.8,
        },
        size: {
          value: { min: 1, max: 5 },
        },
        move: {
          enable: true,
          speed: 2,
          direction: 'none',
          random: true,
          straight: false,
          outModes: {
            default: 'out',
          },
        },
      },
    },
  },
};

// Map emoji codes to variation names for Vibemoji theme
export const emojiMap: Record<string, string> = {
  // Original set
  heart: '❤️',
  party: '🎉',
  sunglasses: '😎',
  clover: '🍀',
  pumpkin: '🎃',
  lollipop: '🍭',
  earth: '🌍',
  fire: '🔥',
  ghost: '👻',
  dragon: '🐉',
  cocktail: '🍸',
  gameday: '🏀',
  demon: '👿',
  alien: '👽',
  skull: '💀',

  // Replacements and additions
  canada: '🇨🇦',
  koala: '🐨',
  coffee: '☕',

  // Additional emojis
  rainbow: '🌈',
  unicorn: '🦄',
  star: '⭐',
  moon: '🌙',
  sun: '☀️',
  pizza: '🍕',
  cake: '🎂',
  crown: '👑',
  guitar: '🎸',
  rose: '🌹',
  balloon: '🎈',
  rocket: '🚀',
  dolphin: '🐬',
  butterfly: '🦋',
  snowflake: '❄️',
  palm: '🌴',
  lightning: '⚡',
  music: '🎵',
  diamond: '💎',
  gift: '🎁',
  camera: '📷',
  book: '📚',
  lightbulb: '💡',
  penguin: '🐧',
  monkey: '🐵',
  car: '🚗',
};
