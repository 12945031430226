'use client';

import { Box } from '@mui/material';
import { useState, useEffect } from 'react';

import DesktopFireWorks from './desktop-fireworks';
import MobileFireworks from './mobile-fireworks';

export default function Fireworks() {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isIOS =
      /iphone|ipad|ipod/.test(userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    const isMobile =
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent) ||
      window.innerWidth <= 768;

    setIsMobileDevice(isIOS || isMobile);
  }, []);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        position: 'relative',
        zIndex: 1,
        padding: 2,
      }}
    >
      {isMobileDevice ? <MobileFireworks /> : <DesktopFireWorks backgroundColor="#000000" />}
    </Box>
  );
}
