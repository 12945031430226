// File: types/theme.ts
export type ThemeVariation =
  | 'heart'
  | 'party'
  | 'sunglasses'
  | 'clover'
  | 'pumpkin'
  | 'lollipop'
  | 'earth'
  | 'fire'
  | 'ghost'
  | 'dragon'
  | 'cocktail'
  | 'gameday'
  | 'demon'
  | 'alien'
  | 'skull'
  | 'america'
  | 'confetti'
  | 'pattern'
  | 'seasonal'
  | 'star'
  | 'snow'
  | 'fireworks'
  | 'starfield'
  | 'cyber';

export type ThemeName = 'Vibemoji' | 'Lowkey' | 'Cosmic' | 'SciFi' | 'BlingBling' | 'Textured';

export type DisplayMode = 'DARK' | 'LIGHT';

export interface EventThemeConfig {
  waitingListEnabled?: boolean;
  isVisible: boolean;
  fontColor?: string;
  fontFamily?: string;
  animation?: string;
  displayMode: DisplayMode;
}

// Utility functions to parse and format the animation string
export const parseThemeString = (
  animationString: string | null
): {
  themeName: ThemeName | null;
  variation: ThemeVariation | null;
  color: string | null;
} => {
  if (!animationString) {
    return { themeName: null, variation: null, color: null };
  }

  const parts = animationString.split('_');
  if (parts.length !== 3) {
    return { themeName: null, variation: null, color: null };
  }

  return {
    themeName: parts[0] as ThemeName,
    variation: parts[1] as ThemeVariation,
    color: `#${parts[2]}`,
  };
};

export const formatThemeString = (
  themeName: ThemeName,
  variation: ThemeVariation,
  color: string
): string => {
  // Remove # from hex color if present
  const cleanColor = color.startsWith('#') ? color.substring(1) : color;
  return `${themeName}_${variation}_${cleanColor}`;
};
