'use client';

import { Box } from '@mui/material';
import { Container } from '@tsparticles/engine';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import React, { useState, useEffect } from 'react';
import { ThemeVariation } from 'src/types/theme';
import { loadFull } from 'tsparticles';

import { sciFiThemeOptions } from './options';
import Starfield from './scifis/stars-pixi';

interface SciFiThemeProps {
  variation: ThemeVariation;
  color: string;
}

export const SciFiTheme: React.FC<SciFiThemeProps> = ({ variation, color }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (variation !== 'starfield') {
      const initParticles = async () => {
        await initParticlesEngine(async (engine) => {
          await loadFull(engine);
        });
        setMounted(true);
      };

      initParticles();
    } else {
      setMounted(true);
    }
  }, [variation]);

  const particlesLoaded = async (container?: Container): Promise<void> => {
    console.log(`SciFi ${variation} theme loaded:`, container);
  };

  if (variation === 'starfield') {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          overflow: 'hidden',
          backgroundColor: color,
        }}
      >
        <Starfield />
      </Box>
    );
  }

  if (!mounted) {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          backgroundColor: color,
        }}
      />
    );
  }

  const variationOptions =
    sciFiThemeOptions[variation] ||
    sciFiThemeOptions[Object.keys(sciFiThemeOptions)[0] || 'default'];

  const options = {
    ...variationOptions.options,
    background: {
      ...variationOptions.options.background,
      color: {
        value: color,
      },
    },
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        overflow: 'hidden',
      }}
    >
      <Particles id="scifi-particles" particlesLoaded={particlesLoaded} options={options} />
    </Box>
  );
};
