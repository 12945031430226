/* eslint-disable no-plusplus */
/* eslint-disable no-inner-declarations */

'use client';

import { Application, Assets, Sprite, Texture } from 'pixi.js';
import React, { useEffect, useRef } from 'react';

interface Star {
  sprite: Sprite;
  x: number;
  y: number;
  z: number;
}

const Starfield: React.FC = () => {
  const canvasRef = useRef<HTMLDivElement>(null);
  const appRef = useRef<Application | null>(null);
  const warpIntervalRef = useRef<number | null>(null);

  useEffect(() => {
    if (typeof window === 'undefined') return undefined;

    let mounted = true;
    let handleClick: ((event: MouseEvent) => void) | undefined;

    // Define randomizeStar function outside of the block
    // to fix "Function declarations are not allowed inside blocks" error
    const randomizeStar = (star: Star, cameraZ: number, initial?: boolean): void => {
      star.z = initial ? Math.random() * 2000 : cameraZ + Math.random() * 1000 + 2000;
      const deg: number = Math.random() * Math.PI * 2;
      const distance: number = Math.random() * 50 + 1;
      star.x = Math.cos(deg) * distance;
      star.y = Math.sin(deg) * distance;
    };

    const initStarfield = async () => {
      const app = new Application();
      appRef.current = app;

      try {
        await app.init({
          resizeTo: window,
          backgroundColor: 0x000000, // Black background
        });

        if (!mounted || !canvasRef.current) return;

        canvasRef.current.appendChild(app.canvas);
        console.log('Canvas added to DOM:', app.canvas);

        // Handle clicks to debug sound trigger
        handleClick = (event: MouseEvent) => {
          console.log('Canvas clicked at:', event.clientX, event.clientY);
          // Check for audio elements again on click
          const audioElements = document.getElementsByTagName('audio');
          console.log('Audio elements found on click:', audioElements.length);
          for (let i = 0; i < audioElements.length; i++) {
            audioElements[i].pause();
            audioElements[i].muted = true;
            console.log('Muted audio element on click:', audioElements[i]);
          }
        };
        app.canvas.addEventListener('click', handleClick);

        let starTexture: Texture;
        try {
          starTexture = await Assets.load('https://pixijs.com/assets/star.png');
          console.log('Star texture loaded successfully');
        } catch (error) {
          console.error('Failed to load star texture:', error);
          starTexture = Texture.WHITE; // Fallback to white square
          console.log('Using fallback texture (white square)');
        }

        const starAmount: number = 1000;
        let cameraZ: number = 0;
        const fov: number = 20;
        const baseSpeed: number = 0.025;
        let speed: number = 0;
        let warpSpeed: number = 0;
        const starStretch: number = 5;
        const starBaseSize: number = 0.05;

        const stars: Star[] = [];

        for (let i = 0; i < starAmount; i++) {
          const star: Star = {
            sprite: new Sprite(starTexture),
            z: 0,
            x: 0,
            y: 0,
          };

          star.sprite.anchor.x = 0.5;
          star.sprite.anchor.y = 0.7;
          randomizeStar(star, cameraZ, true);
          app.stage.addChild(star.sprite);
          stars.push(star);
        }

        warpIntervalRef.current = window.setInterval(() => {
          warpSpeed = warpSpeed > 0 ? 0 : 1;
          console.log('Warp speed toggled:', warpSpeed);
        }, 5000);

        app.ticker.add((time) => {
          if (!mounted || !appRef.current) return;

          speed += (warpSpeed - speed) / 20;
          cameraZ += time.deltaTime * 10 * (speed + baseSpeed);

          for (let i = 0; i < starAmount; i++) {
            const star: Star = stars[i];

            if (star.z < cameraZ) randomizeStar(star, cameraZ);

            const z: number = star.z - cameraZ;
            star.sprite.x =
              star.x * (fov / z) * app.renderer.screen.width + app.renderer.screen.width / 2;
            star.sprite.y =
              star.y * (fov / z) * app.renderer.screen.width + app.renderer.screen.height / 2;

            const dxCenter: number = star.sprite.x - app.renderer.screen.width / 2;
            const dyCenter: number = star.sprite.y - app.renderer.screen.height / 2;
            const distanceCenter: number = Math.sqrt(dxCenter * dxCenter + dyCenter * dyCenter);
            const distanceScale: number = Math.max(0, (2000 - z) / 2000);

            star.sprite.scale.x = distanceScale * starBaseSize;
            star.sprite.scale.y =
              distanceScale * starBaseSize +
              (distanceScale * speed * starStretch * distanceCenter) / app.renderer.screen.width;
            star.sprite.rotation = Math.atan2(dyCenter, dxCenter) + Math.PI / 2;
          }
        });
      } catch (error) {
        console.error('Failed to initialize starfield:', error);
      }
    };

    initStarfield();

    // Return cleanup function with explicit return undefined
    // to fix the "Arrow function expected no return value" error
    return () => {
      mounted = false;
      if (warpIntervalRef.current) {
        clearInterval(warpIntervalRef.current);
        warpIntervalRef.current = null;
      }
      if (appRef.current && handleClick) {
        try {
          appRef.current.canvas.removeEventListener('click', handleClick); // Clean up listener
          appRef.current.destroy(true, { children: true, texture: true });
        } catch (error) {
          console.error('Error during PIXI cleanup:', error);
        }
        appRef.current = null;
      }
      return undefined; // Explicitly return undefined
    };
  }, []);

  return (
    <div
      ref={canvasRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0, // Changed to 0 to ensure visibility
      }}
    />
  );
};

export default Starfield;
