// File: event-theme/themed-backgroud.tsx

'use client';

import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { EventThemeConfig, parseThemeString } from 'src/types/theme';

import { CosmicTheme } from './cosmic';
import { SciFiTheme } from './scifi';
import { VibemojiTheme } from './vibe-moji';

interface ThemeRendererProps {
  themeConfig: EventThemeConfig;
}

const ThemeRenderer: React.FC<ThemeRendererProps> = ({ themeConfig }) => {
  const { themeName, variation, color } = parseThemeString(themeConfig?.animation ?? null);
  const [documentHeight, setDocumentHeight] = useState('100vh');

  // Track document height for full-page coverage
  useEffect(() => {
    const updateHeight = () => {
      // Get the maximum scroll height to ensure full coverage
      const height = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight
      );
      setDocumentHeight(`${height}px`);
    };

    // Initial update
    updateHeight();

    // Update on resize
    window.addEventListener('resize', updateHeight);

    // Update on content changes using MutationObserver
    const observer = new MutationObserver(updateHeight);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
    });

    // Update on scroll - in case dynamic content is loaded
    window.addEventListener('scroll', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
      window.removeEventListener('scroll', updateHeight);
      observer.disconnect();
    };
  }, []);

  if (!themeName || !variation || !color) {
    return (
      <Box
        sx={{
          position: 'fixed', // Changed from absolute to fixed
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: documentHeight, // Use dynamic height
          width: '100%',
          backgroundColor: '#000000',
        }}
      />
    );
  }

  // Render the theme content based on themeName
  const renderThemeContent = () => {
    switch (themeName) {
      case 'Vibemoji':
        return <VibemojiTheme variation={variation} color={color} />;
      case 'Cosmic':
        return <CosmicTheme variation={variation} color={color} />;
      case 'SciFi':
        return <SciFiTheme variation={variation} color={color} />;
      // Add cases for other themes
      default:
        // Default fallback theme
        return (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: color || '#000000',
            }}
          />
        );
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        width: '100%',
        height: documentHeight,
        top: 0,
        left: 0,
        right: 0,
        zIndex: 0,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: '100%',
          width: '100%',
          zIndex: 0,
        }}
      >
        {renderThemeContent()}
      </Box>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: documentHeight,
          width: '100%',
          backgroundColor: 'rgba(34, 34, 34, 0.2)',
          backdropFilter: 'blur(0.2px)',
          WebkitBackdropFilter: 'blur(0.2px)',
          zIndex: 1,
        }}
      />
    </Box>
  );
};

export default ThemeRenderer;
